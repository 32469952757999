<template>
  <div class="User">
    <transition name="fade" mode="out-in">
      <alert v-if="alertOpen" :content="alertContent" @confirm="closeAlert" />
    </transition>
    <div class="panel">
      <div class="Grid-row">
        <div class="header-title">Ban by username</div>
      </div>
      <div class="Grid-row">
        <div class="Grid-column-4">
          <input
            v-model="username"
            :class="{ 'has-error': !username && error }"
            class="input"
            type="text"
            placeholder="Type in username to block"
          />
        </div>
      </div>
      <div class="Grid-row">
        <button class="button button-success" @click="banUser">Ban User</button>
      </div>

      <hr />

      <banned-users-list></banned-users-list>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/common/navigation/Alert';
import BannedUsersList from '@/components/ban-tools/user/BannedUsersList';

export default {
  name: 'User',
  components: {
    Alert,
    BannedUsersList,
  },
  data() {
    return {
      error: false,
      alertOpen: false,
      alertContent: null,
    };
  },
  computed: {
    username: {
      get() {
        return this.$store.getters.username;
      },
      set(value) {
        this.$store.commit('setUsername', { value: value });
      },
    },
  },
  methods: {
    banUser() {
      if (!this.username) {
        this.error = true;
      } else {
        this.$store
          .dispatch('banUser')
          .then(() => {
            this.alertContent = 'User has been banned.';
            this.alertOpen = true;
          })
          .catch(() => {
            this.alertContent =
              'There were errors processing request. Please try again.';
            this.alertOpen = true;
          });
      }
    },
    closeAlert() {
      this.alertOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/ban-tools/user/User.scss';
</style>
